{
  "lv": {
    "js": {
      "redactor": {
        "accessibility-help-label": "Teksta redaktors",
        "add-gap": "Jauna plaisa",
        "add-head": "Pievienot galvu",
        "add-variable": "Pievienot mainīgo",
        "align": "Saskaņot",
        "align-center": "Saskaņot centru",
        "align-left": "Izlīdzināt pa kreisi",
        "align-right": "Izlīdzināt pa labi",
        "allow-microphone-access": "Lūdzu, atļaujiet piekļuvi mikrofonam",
        "audio": "Audio ieraksts",
        "background": "Pamatinformācija",
        "background-color": "Fono spalva",
        "blockquote": "Citēt",
        "bold": "Bold",
        "bold-abbr": "B",
        "bulletslist": "Bullets",
        "cancel": "Atcelt",
        "caption": "Virsraksts",
        "center": "Centrs",
        "change": "Mainīt",
        "change-picture": "Mainīt attēlu",
        "characterLimit": "Jūs nevarat ielīmēt vairāk par 5000 rakstzīmēm",
        "chars": "Chars",
        "choose": "Izvēlieties",
        "close": "Aizvērt",
        "code": "Kods",
        "delete": "Dzēst",
        "delete-column": "Dzēst kolonnu",
        "delete-head": "Dzēst galvu",
        "delete-row": "Dzēst rindu",
        "delete-table": "Tabulas dzēšana",
        "deleted": "Pārsvītrot",
        "deleted-abbr": "S",
        "drawing": "Zīmēšana",
        "edit": "Rediģēt",
        "equation": "Vienādojums",
        "error-accessing-microphone": "Kļūda, piekļūstot jūsu mikrofonam",
        "file": "Failu",
        "filename": "Nosaukums",
        "fontcolor": "Teksta krāsa",
        "format": "Formāts",
        "fullscreen": "Pilnekrāna",
        "gap": "Plaisa",
        "grade-formula-points": "Iegūtie punkti",
        "grade-formula-total": "Kopējais punktu skaits",
        "heading": "Pozīcija",
        "heading1": "Pozīcija 1",
        "heading2": "Pozīcija 2",
        "heading3": "Pozīcija 3",
        "heading4": "Pozīcija 4",
        "heading5": "Pozīcija 5",
        "heading6": "Pozīcija 6",
        "horizontalrule": "Līnija",
        "image": "Attēls",
        "image-position": "Pozīcija",
        "indent": "Atkāpes",
        "insert": "Ievietot",
        "insert-column-left": "Kolonnas ievietošana kreisajā pusē",
        "insert-column-right": "Kolonnas ievietošana pa labi",
        "insert-row-above": "Ievietot rindu virs",
        "insert-row-below": "Ievietot rindu zemāk",
        "insert-table": "Tabulas ievietošana",
        "italic": "Kursīvais",
        "italic-abbr": "I",
        "left": "Kreisā",
        "link": "Saite",
        "link-edit": "Rediģēt saiti",
        "link-in-new-tab": "Atvērt saiti jaunā cilnē",
        "link-insert": "Saites ievietošana",
        "lists": "Saraksti",
        "make-recording-warning": "Lūdzu, vispirms veiciet ierakstu",
        "maxWords": "Maksimālais vārdu skaits",
        "no-variables": "Nav definēti mainīgie lielumi",
        "none": "Nav",
        "numberslist": "Skaitļi",
        "optionList": "Iespēju saraksts",
        "optional": "Pēc izvēles",
        "orderedlist": "Pasūtītais saraksts",
        "outdent": "Outdent",
        "paragraph": "Parasts teksts",
        "pause": "Pauze",
        "pre": "Kodu",
        "quote": "Citēt",
        "record": "Ieraksts",
        "redo": "Pārtaisīt",
        "remove": "Noņemt",
        "resume": "CV",
        "right": "Tiesības",
        "save": "Saglabāt",
        "save as shortcut": "Saglabāt kā ātru komentāru",
        "shortcuts": "Īsi komentāri",
        "show shortcuts": "Rādīt ātros komentārus",
        "specialchars": "Īpašie rakstzīmes",
        "stop": "Pārtraukt",
        "stream-unavailable": "Video straume nav pieejama.",
        "subscript": "Indekss",
        "subscript-abbr": "Sub",
        "superscript": "Superscript",
        "superscript-abbr": "Sup",
        "table": "Tabula",
        "take-picture": "Uzņemiet attēlu",
        "take-picture-warning": "Vispirms nofotografējiet",
        "text": "Teksts",
        "text-color": "Teksto spalva",
        "title": "Alt",
        "twemoji": "Emocijas",
        "underline": "Pasvītrot",
        "underline-abbr": "U",
        "undo": "Atcelt",
        "unlink": "Atvienojiet saiti",
        "unorderedlist": "Neorganizēts saraksts",
        "upload": "Augšupielādēt",
        "upload-change-label": "Ievietojiet šeit jaunu attēlu, lai to rediģētu",
        "upload-label": "Lejupielādējiet failus šeit vai noklikšķiniet, lai augšupielādētu",
        "variable": "Mainīgs",
        "webcam": "Tīmekļa kameras ekrānšāviņš",
        "wordLimit": "Sasniegts vārdu ierobežojums",
        "words": "Vārdi"
      }
    }
  }
}